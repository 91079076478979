@page: left {
  margin-top: 1in !important;
  margin-bottom: 1in !important;
  margin-left: 1in !important;
  margin-right: 1in !important;
}

@page: right {
  margin-top: 1in !important;
  margin-bottom: 1in !important;
  margin-left: 1in !important;
  margin-right: 1in !important;
}

:root {
  --main-primary: #df394e;
  --main-primary-90: #e24d60;
  --main-primary-80: #e56171;
  --main-primary-70: #e97483;
  --main-primary-60: #ec8895;
  --main-primary-50: #ef9ca7;
  --main-primary-40: #f2b0b8;
  --main-primary-30: #f5c4ca;
  --main-primary-20: #f9d7dc;
  --main-primary-10: #fcebed;

  --main-secondary: #d0b4a0;
  --main-secondary-90: #d5bba9;
  --main-secondary-80: #d9c3b3;
  --main-secondary-70: #decbbd;
  --main-secondary-60: #e3d2c6;
  --main-secondary-50: #e7d9cf;
  --main-secondary-40: #ece1d9;
  --main-secondary-30: #f1e9e3;
  --main-secondary-20: #f6f0ec;
  --main-secondary-10: #faf7f5;

  --main-success: #24c48e;
  --main-success-90: #3aca99;
  --main-success-80: #50d0a5;
  --main-success-70: #66d6b0;
  --main-success-60: #7cdcbb;
  --main-success-50: #91e1c7;
  --main-success-40: #a7e7d2;
  --main-success-30: #bdeddd;
  --main-success-20: #d3f3e8;
  --main-success-10: #e9f9f4;

  --main-danger: #df394e;
  --main-danger-90: #e24d60;
  --main-danger-80: #e56171;
  --main-danger-70: #e97483;
  --main-danger-60: #ec8895;
  --main-danger-50: #ef9ca7;
  --main-danger-40: #f2b0b8;
  --main-danger-30: #f5c4ca;
  --main-danger-20: #f9d7dc;
  --main-danger-10: #fcebed;

  --main-warning: #ffc107;
  --main-warning-90: #ffc720;
  --main-warning-80: #ffcd39;
  --main-warning-70: #ffd451;
  --main-warning-60: #ffda6a;
  --main-warning-50: #ffe083;
  --main-warning-40: #ffe69c;
  --main-warning-30: #ffecb5;
  --main-warning-20: #fff3cd;
  --main-warning-10: #fff9e6;

  --main-info: #17a2b8;
  --main-info-90: #2eabbf;
  --main-info-80: #45b5c6;
  --main-info-70: #5dbecd;
  --main-info-60: #74c7d4;
  --main-info-50: #8bd0db;
  --main-info-40: #a2dae3;
  --main-info-30: #b9e3ea;
  --main-info-20: #d1ecf1;
  --main-info-10: #e8f6f8;

  --grayscale-100: #495057;
  --grayscale-90: #5a6067;
  --grayscale-80: #6c7177;
  --grayscale-70: #7d8287;
  --grayscale-60: #8f9398;
  --grayscale-50: #a0a4a8;
  --grayscale-40: #b2b5b8;
  --grayscale-30: #d5d7d9;
  --grayscale-20: #e6e8e9;
  --grayscale-10: #f8f9fa;

  --other-1-100: #44b7c2;
  --other-1-90: #57bec8;
  --other-1-80: #69c5ce;
  --other-1-70: #7ccdd4;
  --other-1-60: #8fd4da;
  --other-1-50: #a1dbe0;
  --other-1-40: #b4e2e7;
  --other-1-30: #c7e9ed;
  --other-1-20: #daf1f3;
  --other-1-10: #ecf8f9;

  --other-2-100: #024b79;
  --other-2-90: #1b5d86;
  --other-2-80: #356f94;
  --other-2-70: #4e81a1;
  --other-2-60: #6793af;
  --other-2-50: #80a5bc;
  --other-2-40: #9ab7c9;
  --other-2-30: #b3c9d7;
  --other-2-20: #ccdbe4;
  --other-2-10: #e6edf2;

  --other-3-100: #ffad48;
  --other-3-90: #ffb55a;
  --other-3-80: #ffbd6d;
  --other-3-70: #ffc67f;
  --other-3-60: #ffce91;
  --other-3-50: #ffd6a3;
  --other-3-40: #ffdeb6;
  --other-3-30: #ffe6c8;
  --other-3-20: #ffefda;
  --other-3-10: #fff7ed;

  --other-4-100: #ba5800;
  --other-4-90: #c1691a;
  --other-4-80: #c87933;
  --other-4-70: #cf8a4d;
  --other-4-60: #d69b66;
  --other-4-50: #dcab80;
  --other-4-40: #e3bc99;
  --other-4-30: #eacdb3;
  --other-4-20: #f1decc;
  --other-4-10: #f8eee5;

  --other-5-100: #dbc500;
  --other-5-90: #decb1a;
  --other-5-80: #e2d133;
  --other-5-70: #e6d64d;
  --other-5-60: #e9dc66;
  --other-5-50: #ede280;
  --other-5-40: #f1e899;
  --other-5-30: #f4eeb3;
  --other-5-20: #f8f3cc;
  --other-5-10: #fbf9e5;
}

.text-green {
  color: var(--main-success);
}
.text-red {
  color: var(--main-danger);
}
.btn-primary {
  background-color: var(--main-primary);
  border-color: var(--main-primary);
}
.btn-primary:hover {
  background-color: var(--main-primary-80);
  border-color: var(--main-primary);
}
.btn-primary:active {
  background-color: var(--main-primary-80) !important;
  border-color: var(--main-primary) !important;
  box-shadow: 0 0 0 0.2rem var(--main-primary-50) !important;
}
.btn-primary:focus {
  background-color: var(--main-primary-80);
  border-color: var(--main-primary);
  box-shadow: 0 0 0 0.2rem var(--main-primary-50);
}
.btn-primary.disabled {
  background-color: var(--main-primary-50);
  border-color: var(--main-primary-50);
}

.btn-grayscale {
  background-color: var(--grayscale-50);
  color: #fff;
}
.btn-grayscale:hover {
  background-color: var(--grayscale-60);
  color: #fff;
}
.btn-grayscale:active {
  background-color: var(--grayscale-60) !important;
  box-shadow: 0 0 0 0.2rem var(--grayscale-50) !important;
  color: #fff;
}
.btn-grayscale:focus {
  background-color: var(--grayscale-60);
  box-shadow: 0 0 0 0.2rem var(--grayscale-50);
  color: #fff;
}
.btn-grayscale.disabled {
  background-color: var(--grayscale-30);
  border-color: var(--grayscale-30);
}
body {
  color: #495057;
  margin: 0;
  max-height: 100vh;
}

#root.center {
  min-height: 100vh;
  background: #383b4a;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.hover-primary > *:hover {
  background-color: var(--grayscale-10) !important;
  /* transition: background 0.2s ease-in; */
  cursor: pointer;
}
.selected-primary > * {
  /* transition: background 0.2s ease-in; */
  background-color: var(--main-primary-10) !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
.scroll-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
}

.modal-full {
  min-width: 100%;
  margin: 0;
}

.modal-full .modal-content {
  min-height: 100vh;
  border-radius: 0;
}

.modal-full .modal-body {
  padding: 0 !important;
}

/*camera*/

#video_preview {
  /*position: fixed;*/
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#btnTakePicture {
  height: 100%;
  width: auto;
}

.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

.btn-circle {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.thumb {
  max-width: 80%;
  max-height: 80%;
  border: 1px solid #000;
  margin: 10px 5px 0 0;
}

.task {
  cursor: pointer;
}

.showImage {
  cursor: pointer;
  text-decoration: underline;
}

/**
* Design 3
*/

li.nav-item.active {
  background: #808080;
  color: #f1f1f1;
}

li.nav-item.active > a.nav-link {
  color: white;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  padding-left: 4em;
  padding-right: 4em;
}

.sec-nav {
  background: #c7c7c7;
  border-bottom: solid #808080 2px;
}

/*
*  Sidebar style
*/

/*
  DEMO STYLE
*/

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease-out;
}

.navbar {
  padding: 11px 10px;
  background: #fff;
}

.navbar-header {
  color: #a5a5a5;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */
#root {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 315px;
  max-width: 315px;
  background: #fff;
  transition: all 0.1s ease-out;
  height: 100vh;
  position: sticky;
  top: 0;
}

.modules .active {
  color: var(--main-primary);
  border-left: solid var(--main-primary);
  font-weight: 600;
}

#sidebar.active {
  margin-left: -315px;
}

#sidebar.overlay {
  display: flex;
  position: fixed;
  z-index: 100;
  top: 3em;
  bottom: 0;
  overflow-x: scroll;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #8c8c8c;
}

#sidebar ul li a:hover {
  background: var(--grayscale-10);
}

#sidebar ul li.active > a {
  background: var(--main-primary-10);
  border-left: solid 5px var(--main-primary);
  font-weight: 500;
}

a[aria-expanded="false"],
a[aria-expanded="true"] {
  position: relative;
  position: relative;
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
  content: "\f078";
  display: block;
  position: absolute;
  right: 20px;
  font-family: "Font Awesome\ 5 Free";
  font-size: 0.45em;
  padding-top: 6px;
  font-weight: 900;
}
a[aria-expanded="true"]::before {
  content: "\f077";
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 60px !important;
}

a.download {
  background: #fff;
  color: #a2a2a2;
}

a.article,
a.article:hover {
  background: #bbbbbb !important;
  color: #fff !important;
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  transition: all 0.1s ease-out;
}

#sidebarCollapse:focus {
  outline: none !important;
  box-shadow: none;
}

table {
  border-spacing: 0;
}

/* ---------------------------------------------------
  MEDIA QUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebar {
    margin-left: -315px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

/* ---------------------------------------------------
  WordPress Theme overwrites
----------------------------------------------------- */

body h1 {
  font-family: inherit;
  font-size: 2.5rem;
}

body a {
  color: inherit;
}

/* ---------------------------------------------------
  Bootstrap overwrites
----------------------------------------------------- */
.breadcrumb {
  border-radius: 0;
  margin-bottom: 0;
}
.card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: none;
}
.card-header {
  background: #fff;
  border-bottom: 0;
  font-weight: 600;
  color: #4c4c4c;
}
.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: rgb(204, 204, 204) 0px 1px 2px;
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}
.form-control:focus {
  border-color: none;
  box-shadow: none;
}

/* ---------------------------------------------------
  Circular progress bar overwrites
----------------------------------------------------- */
.CircularProgressbar .CircularProgressbar-text {
  fill: #707070;
  font-weight: 700;
}
/* ---------------------------------------------------
  rc-tree overwrites
----------------------------------------------------- */
.rc-tree-select-selection {
  border: 0;
  border-radius: 0;
}

/* ---------------------------------------------------
  Left sidebar
----------------------------------------------------- */

.modal-backdrop.show {
  opacity: 0.85;
  background-color: #fff;
}
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 520px;
  max-width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: -520px;
}

/* ----- MODAL STYLE ----- */
.right > .modal-content {
  border-radius: 0;
  border: none;
}

.right > .modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}
/* ---------------------------------------------------
  animation
----------------------------------------------------- */
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.failed-login {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 12px;
  width: 12px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: var(--main-danger);
  box-shadow: 0 0 0 0 var(--main-danger-50);
}

.blob.yellow {
  background: var(--main-warning);
  box-shadow: 0 0 0 0 var(--main-warning-50);
}

.animate {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 var(--main-danger-50);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
