/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal-backdrop.in {
  filter: alpha(opacity=85);
  opacity: 0.85;
  background-color: #fff;
}
.modal .modal-dialog .left,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal .modal-content .left,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal .modal-body .left,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.fade .modal-dialog .left {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.fade.in .modal-dialog .left {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.left > .modal-content {
  border-radius: 0;
  border: none;
}

.left > .modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}
